import React from "react";

import Navigation from "../../components/Layout/NavigationTW/NavigationTW";
import Footer from "../../components/Layout/FooterTW/FooterTW";

const PageLayout = (props) => {
  return (
    <div class="relative bg-gray-50">
      <div
        x-data="{mobileMenuOpen: false}"
        class="fixed top-0 left-0 right-0 bg-white shadow z-20"
      >
        <Navigation />
      </div>
      {props.children}
      <Footer />
    </div>
  );
};

export default PageLayout;
