import React from "react";
import PageLayout from "../../../hoc/PageLayoutTW/PageLayoutTW";
import Questions from "../../../components/QuestionsTW/QuestionsTW";
import "alpinejs";
import { locale } from "../../../translate/serbian";
import PageLayoutBottom from "../../../components/PageLayoutBottom/PageLayoutBottom";

const monhlyPricesTemplate = `<div class="bg-white mt-20">
<div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
  <div
    class="sm:flex sm:flex-col sm:align-center"
    x-data="{monthly: true}"
  >
    <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">
      ${locale.pricing.heading}
    </h1>
    <p class="mt-5 text-xl text-gray-500 sm:text-center">
      ${locale.pricing.description}
    </p>
    <div class="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
      <button
      x-show="monthly" x-on:click="monthly = true; $dispatch('pricing-toggle', {monthly: monthly})"
        type="button"
        class="relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8"
      >
        ${locale.pricing.monthly}
      </button>
      <button
        x-show="monthly"
        x-on:click="monthly = false; $dispatch('pricing-toggle', {monthly: monthly})"
        type="button"
        class="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8"
      >
        ${locale.pricing.annualy}
      </button>

      <button
      x-show="!monthly" x-on:click="monthly = true; $dispatch('pricing-toggle', {monthly: monthly})"
        type="button"
        class="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8"
      >
			${locale.pricing.monthly}
      </button>
      <button
      x-show="!monthly" x-on:click="monthly = false; $dispatch('pricing-toggle', {monthly: monthly})"
        type="button"
        class="relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8"
      >
			${locale.pricing.annualy}
      </button>
    </div>
  </div>
  <div
    x-data="{monthly: true}"
    x-on:pricing-toggle.window="monthly = $event.detail.monthly"
    class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3"
  >
    <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
      <div class="p-6">
        <h2 class="text-lg leading-6 font-medium text-gray-900">
          ${locale.pricing.solo.title}
        </h2>
        <p class="mt-4 text-sm text-gray-500">
          ${locale.pricing.solo.description}
        </p>
        <p class="mt-8">
          <span
            x-show="monthly"
            class="text-4xl font-extrabold text-gray-900"
          >
            ${locale.pricing.solo.priceMonthly}
          </span>
          <span
            x-show="!monthly"
            class="text-4xl font-extrabold text-gray-900"
          >
					${locale.pricing.solo.priceAnnualy}
          </span>
        
          <span x-show="!monthly" class="text-base font-medium text-gray-500">
            / ${locale.pricing.annualy}
          </span>
          <span x-show="monthly" class="text-base font-medium text-gray-500">
            / ${locale.pricing.monthly}
          </span>

        </p>
            <a
              href="/signup"
              class="mt-8 block w-full bg-blue-600 border border-blue-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
            >
						${locale.tryFree}
            </a>
      </div>
      <div class="pt-6 pb-8 px-6">
        <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">
          ${locale.pricing.solo.checkItemsTitle}
        </h3>
        <ul class="mt-6 space-y-4">
					<li class="flex space-x-3">
						<svg
							class="flex-shrink-0 h-5 w-5 text-green-500"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
								clip-rule="evenodd"
							/>
						</svg>
						<span class="text-sm text-gray-500">${locale.pricing.solo.checkItems[0]}</span>
					</li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">${locale.pricing.solo.checkItems[1]}</span>
          </li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">${locale.pricing.solo.checkItems[2]}</span>
          </li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">${locale.pricing.solo.checkItems[3]}</span>
          </li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
						<span class="text-sm text-gray-500">${locale.pricing.solo.checkItems[4]}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
      <div class="p-6">
        <h2 class="text-lg leading-6 font-medium text-gray-900">
          ${locale.pricing.team.title}
        </h2>
        <p class="mt-4 text-sm text-gray-500">
					${locale.pricing.team.description}
        </p>
        <p class="mt-8">
          <span
            x-show="monthly"
            class="text-4xl font-extrabold text-gray-900"
          >
					${locale.pricing.team.priceMonthly}
          </span>
          <span
            x-show="!monthly"
            class="text-4xl font-extrabold text-gray-900"
          >
					${locale.pricing.team.priceAnnualy}
          </span>
          <span x-show="!monthly" class="text-base font-medium text-gray-500">
            / ${locale.pricing.annualy}
          </span>
          <span x-show="monthly" class="text-base font-medium text-gray-500">
            / ${locale.pricing.monthly}
          </span>

        </p>
          <a
            href="/signup"
            class="mt-8 block w-full bg-blue-600 border border-blue-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
          >
					${locale.tryFree}
          </a>
      </div>
      <div class="pt-6 pb-8 px-6">
        <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">
					${locale.pricing.team.checkItemsTitle}
        </h3>
        <ul class="mt-6 space-y-4">
          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">
							${locale.pricing.team.checkItems[0]}
            </span>
          </li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">
							${locale.pricing.team.checkItems[1]}
            </span>
          </li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">
						${locale.pricing.team.checkItems[2]}
            </span>
          </li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">${locale.pricing.team.checkItems[3]}</span>
          </li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">${locale.pricing.team.checkItems[4]}</span>
          </li>

          <li class="flex space-x-3">
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">
							${locale.pricing.team.checkItems[5]}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
      <div class="p-6">
        <h2 class="text-lg leading-6 font-medium text-gray-900">
          ${locale.pricing.deluxe.title}
        </h2>
        <p class="mt-4 text-sm text-gray-500">
          ${locale.pricing.deluxe.description}
        </p>

          <a
          href="/signup"
            class="mt-8 block w-full bg-blue-600 border border-blue-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
          >
            ${locale.tryFree}
          </a>
      </div>
      <div class="pt-6 pb-8 px-6">
        <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">
          ${locale.pricing.deluxe.checkItemsTitle}
        </h3>
				<ul class="mt-6 space-y-4">
				<li class="flex space-x-3">
					<svg
						class="flex-shrink-0 h-5 w-5 text-green-500"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clip-rule="evenodd"
						/>
					</svg>
					<span class="text-sm text-gray-500">
						${locale.pricing.deluxe.checkItems[0]}
					</span>
				</li>

				<li class="flex space-x-3">
					<svg
						class="flex-shrink-0 h-5 w-5 text-green-500"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clip-rule="evenodd"
						/>
					</svg>
					<span class="text-sm text-gray-500">
						${locale.pricing.deluxe.checkItems[1]}
					</span>
				</li>

				<li class="flex space-x-3">
					<svg
						class="flex-shrink-0 h-5 w-5 text-green-500"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clip-rule="evenodd"
						/>
					</svg>
					<span class="text-sm text-gray-500">
					${locale.pricing.deluxe.checkItems[2]}
					</span>
				</li>

				<li class="flex space-x-3">
					<svg
						class="flex-shrink-0 h-5 w-5 text-green-500"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clip-rule="evenodd"
						/>
					</svg>
					<span class="text-sm text-gray-500">${locale.pricing.deluxe.checkItems[3]}</span>
				</li>

				<li class="flex space-x-3">
					<svg
						class="flex-shrink-0 h-5 w-5 text-green-500"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clip-rule="evenodd"
						/>
					</svg>
					<span class="text-sm text-gray-500">${locale.pricing.deluxe.checkItems[4]}</span>
				</li>

				<li class="flex space-x-3">
					<svg
						class="flex-shrink-0 h-5 w-5 text-green-500"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clip-rule="evenodd"
						/>
					</svg>
					<span class="text-sm text-gray-500">
						${locale.pricing.deluxe.checkItems[5]}
					</span>
				</li>
			</ul>
      </div>
    </div>
  </div>
</div>
</div>`;

const MonthlyPrices = () => (
  <div dangerouslySetInnerHTML={{ __html: monhlyPricesTemplate }} />
);

const ContactTW = () => {
  return (
    <PageLayout>
      <MonthlyPrices />
      <Questions />
      <PageLayoutBottom />
    </PageLayout>
  );
};

export default ContactTW;
