import React from "react";
import "./WholeLogin.scss";

import { Link } from "react-router-dom";

// import LoginWith from "../../../components/Login/LoginWith/LoginWith";
import LoginForm from "../../../components/Login/LoginForm/LoginForm";
import { locale } from "../../../translate/serbian";

// import Google from "../../../assets/Signup/Google.png";
// import Facebook from "../../../assets/Signup/Facebook.png";

const wholeLogin = (props) => {
  return (
    <div className="WholeLogin">
      <LoginForm {...props} />
      {/* <p className="or">OR</p>
      <LoginWith img={Google} name="Google" />
      <LoginWith img={Facebook} name="Facebook" /> */}

      <div className="noAccount">
        <p>{locale.auth.login.dontHaveAcc} </p>
        <Link to="/signup">{locale.auth.login.signupBusiness}</Link>
      </div>
    </div>
  );
};

export default wholeLogin;
