
import * as actionTypes from '../actions/actionTypes'

const initialState = []

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TYPE_OF_SERVICES:
      return action.typeOfServices
    default:
      return state
  }
}

export default reducer
