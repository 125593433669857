import * as actionTypes from "../actions/actionTypes";
import axios from "../../utility/axios/axios";

import { startLoading, finishLoading } from "./loader";
import { successNotification, errorsNotification } from "./notification";
import { setPlace } from "./place";

export const setUser = (user) => {
  return {
    type: actionTypes.SET_USER,
    user: user,
  };
};

export const login = (email, password, redirect) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        axios
          .post("/login", { email, password })
          .then((response) => {
            // dispatch(getProfile())
            dispatch(finishLoading());
            if (redirect) {
              window.location.href = process.env.REACT_APP_CALENDAR_LINK;
            }
            dispatch(successNotification(response.data.message));
          })
          .catch((error) => {
            dispatch(errorsNotification(error.response.data.message));
            dispatch(finishLoading());
          });
      })
      .catch((error) => {
        dispatch(finishLoading());
      });
  };
};

export const postUser = (
  email,
  firstName,
  lastName,
  phone,
  password,
  passwordConfirmation,
  companyName,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/signup/step/1", {
        email: email,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        password: password,
        password_confirmation: passwordConfirmation,
        company_name: companyName,
      })
      .then((response) => {
        // console.log("USER",response.data.data.user)
        dispatch(setUser(response.data.data.user));
        dispatch(successNotification(response.data.message));
        dispatch(login(email, password));
        dispatch(finishLoading());
        callback(2);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          Object.keys(error.response.data.errors).map((errorData) =>
            error.response.data.errors[errorData].map((message) =>
              dispatch(errorsNotification(message))
            )
          );
        } else dispatch(errorsNotification(error.response.data.message));

        dispatch(finishLoading());
      });
  };
};

export const sendEmail = (name, email, phone) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/demo/subscribe", {
        name,
        email,
        phone,
      })
      .then((response) => {
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.response.status === 422) {
          Object.keys(error.response.data.errors).map((errorData) =>
            error.response.data.errors[errorData].map((message) =>
              dispatch(errorsNotification(message))
            )
          );
        } else {
          dispatch(errorsNotification(error.response.data.message));
        }

        dispatch(finishLoading());
      });
  };
};

export const signUp = (email, password, website, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        axios
          .post("/api/b2b/signup", {
            email,
            password,
            website,
          })
          .then((response) => {
            // console.log("USER",response.data.data.user)
            dispatch(successNotification(response.data.message));
            dispatch(finishLoading());
            callback();
          })
          .catch((error) => {
            if (error.response.status === 422) {
              Object.keys(error.response.data.errors).map((errorData) =>
                error.response.data.errors[errorData].map((message) =>
                  dispatch(errorsNotification(message))
                )
              );
            } else dispatch(errorsNotification(error.response.data.message));

            dispatch(finishLoading());
          });
      })
      .catch((error) => {
        dispatch(finishLoading());
      });
  };
};

export const setWidgetInfo = (payload) => {
  return {
    type: actionTypes.SET_WIDGET_INFO,
    payload,
  };
};

export const widgetCustomization = (FormData, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/signup/widget/step/2", FormData, {})
      .then((response) => {
        dispatch(setWidgetInfo(response.data.data));
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }

        dispatch(finishLoading());
      });
  };
};

export const sendInstructions = (email, uuid) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/signup/widget/instructions", {
        email,
        uuid,
      })
      .then((response) => {
        // console.log("USER",response.data.data.user)
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }

        dispatch(finishLoading());
      });
  };
};

export const finishSignUp = (uuid) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/signup/widget/step/3", {
        uuid,
      })
      .then((response) => {
        // console.log("USER",response.data.data.user)
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        window.location.href = process.env.REACT_APP_CALENDAR_LINK;
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }

        dispatch(finishLoading());
      });
  };
};

export const postUserSpain = (
  first_name,
  last_name,
  phone,
  email,
  password,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/signup/step/1", {
        first_name,
        last_name,
        phone,
        email,
        password,
        password_confirmation: password,
      })
      .then((response) => {
        // console.log("USER",response.data.data.user)
        dispatch(setUser(response.data.data.user));
        dispatch(successNotification(response.data.message));
        dispatch(login(email, password));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }
        dispatch(finishLoading());
      });
  };
};

export const step2 = (businessName, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/signup/step/2", {
        name: businessName,
      })
      .then((response) => {
        dispatch(setPlace(response.data.data.place));
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }
        dispatch(finishLoading());
      });
  };
};

export const step3 = (place_id, main_category, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/signup/step/3", {
        place_id,
        main_category,
      })
      .then((response) => {
        // console.log("USER",response.data.data.user)
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }
        dispatch(finishLoading());
      });
  };
};

export const step4 = (place_id, additional_categories, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/b2b/signup/step/4", {
        place_id,
        additional_categories,
      })
      .then((response) => {
        // console.log("USER",response.data.data.user)
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }
        dispatch(finishLoading());
      });
  };
};
