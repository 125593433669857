import React from "react";
import PageLayout from "../../../hoc/PageLayoutTW/PageLayoutTW";
import Questions from "../../../components/QuestionsTW/QuestionsTW";
import "alpinejs";
import PageLayoutBottom from "../../../components/PageLayoutBottom/PageLayoutBottom";

const ContactTW = () => {
  return (
    <PageLayout>
      <div class="mt-20">
        <Questions />
      </div>
      <PageLayoutBottom />
    </PageLayout>
  );
};

export default ContactTW;
