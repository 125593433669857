import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PLACE:
      console.log(action);
      return action.place;
    default:
      return state;
  }
};

export default reducer;
