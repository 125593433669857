import React from "react";
import "./Login.scss";
import { Link, useHistory } from "react-router-dom";

import Bookybook from "../../../assets/Signup/Logo.png";
import WholeLogin from "../../../components/Login/WholeLogin/WholeLogin";
import { BiArrowBack } from "react-icons/bi";
import { locale } from "../../../translate/serbian";

const Login = (props) => {
  const history = useHistory();
  return (
    <div className="Login">
      <div className="topArea">
        <div className="back" onClick={() => history.goBack()}>
          <BiArrowBack />
        </div>
        <Link to="/">
          <img className="logo" src={Bookybook} alt="Bookybook" />
        </Link>
      </div>
      <div className="loginContent">
        <h1>{locale.auth.login.title}</h1>
        <p className="description">{locale.auth.login.description}</p>
        <WholeLogin />
      </div>
      <div className="loginContent professionals">
        <h1>{locale.auth.login.bookingAsCustomer}</h1>
        <p>
          {locale.auth.login.bookingAsCustomerText}{" "}
          <a href={process.env.REACT_APP_LOGIN_LINK}>
            {locale.auth.login.bookerLogin}
          </a>{" "}
          {locale.auth.login.instead}
        </p>
      </div>
      <span className="allRights">© {locale.footer.copyRight}</span>
    </div>
  );
};

export default Login;
