import * as actionTypes from '../actions/actionTypes'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_OPENING_HOURS:
      return action.openingHours
    default:
      return state
  }
}

export default reducer
