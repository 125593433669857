import React, { useEffect } from "react";
import "./BookingInfo.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Logo from "../../../assets/Signup/Logo.png";
import {
  cancelAppointment,
  getBookingInfo,
} from "../../../store/actions/booking";
import dayjs from "dayjs";

const daysNames = [
  "Söndag",
  "Måndag",
  "Tisdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lördag",
];

const BookingInfo = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const bookingInfo = useSelector((state) => state.booking);

  useEffect(() => {
    dispatch(getBookingInfo(id, history));
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    if (bookingInfo) {
      dispatch(cancelAppointment(bookingInfo.cancellation_code, history));
    }
  };

  return (
    <div className="infoBg">
      <div className="BookingInfo">
        <div className="info">
          <img src={Logo} alt="Logo" />
          <h1>
            Din bokning hos {bookingInfo && bookingInfo.place.name}{" "}
            <span role="img" aria-label="cloud">
              🎉
            </span>
          </h1>
          <p>
            {bookingInfo &&
              `${daysNames[dayjs(bookingInfo.date).day()]} ${dayjs(
                bookingInfo.date
              ).format("DD MMM. YYYY")} kl. ${bookingInfo.from.substring(
                0,
                5
              )}`}
          </p>
          <h5>Tjänst</h5>
          <p>{bookingInfo && `${bookingInfo.service.name}`}</p>
          <h5>Datum & Tid</h5>
          <p>
            {bookingInfo &&
              `${daysNames[dayjs(bookingInfo.date).day()]} ${dayjs(
                bookingInfo.date
              ).format("DD MMM. YYYY")} kl. ${bookingInfo.from.substring(
                0,
                5
              )}`}
          </p>
          <h5>Utförare</h5>
          <p>
            {bookingInfo &&
              `${bookingInfo.employee.first_name} ${bookingInfo.employee.last_name}`}
          </p>
          <h5 className="last">Avbokningspolicy</h5>
          <p>
            Din bokning kan avbokas senast{" "}
            {bookingInfo && bookingInfo.cancellation_time_string}
          </p>
          <button onClick={handleCancel}>Avboka</button>
          <h5>
            Undrar du något?{" "}
            <span role="img" aria-label="cloud">
              💭
            </span>
          </h5>
          <p>
            Vid frågor kring bokningen kontaktar du{" "}
            {bookingInfo && bookingInfo.place.name} på{" "}
            <a href={bookingInfo ? `tel:${bookingInfo.place.phone}` : "#!"}>
              {bookingInfo && bookingInfo.place.phone}
            </a>{" "}
            för att få hjälp så snabbt som möjligt.
          </p>
        </div>
        <p>
          Du får detta meddelandet för att du gjort en bokning på{" "}
          {bookingInfo && bookingInfo.place.name} via Bookybook.© 2021 Bookybook
          AB
        </p>
      </div>
    </div>
  );
};

export default BookingInfo;
