import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import loader from "./store/reducers/loader";
import notification from "./store/reducers/notification";
import categories from "./store/reducers/categories";
import openingHours from "./store/reducers/openingHours";
import services from "./store/reducers/services";
import typeofservices from "./store/reducers/typeofservices";
import place from "./store/reducers/place";
import workingHours from "./store/reducers/workingHours";
import calendars from "./store/reducers/calendar";
import user from "./store/reducers/user";
import booking from "./store/reducers/booking";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


Sentry.init({
  dsn: "https://d4d7ffb09b9442c78bea89136ebd3078@o275620.ingest.sentry.io/6168654",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const rootReducer = combineReducers({
  // account:account,
  loader,
  notification,
  categories,
  openingHours,
  services,
  typeofservices,
  place,
  workingHours,
  calendars,
  user,
  booking,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
