import React from "react";
import "./ServicesCustomization.scss";
// import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Signup/SignupInput2/SignupInput2";
// import { FaCheckCircle } from "react-icons/fa";
//actions
// import { fetchCategories } from "../../../store/actions/categories";

const ServicesCustomization = (props) => {
  // const dispatch = useDispatch();
  // const categories = useSelector((state) => state.categories);

  // useEffect(() => {
  //   categories.length === 0 && dispatch(fetchCategories());
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className="ServicesCustomization">
      <form>
        <h1>Kontouppgifter</h1>
        <div className="inputs">
          <Input
            label="Förnamn"
            placeholder="ex. John"
            value={props.firstName}
            onChange={(val) => props.setFirstName(val)}
          />
          <Input
            label="Efternamn"
            placeholder="ex. Doe"
            value={props.lastName}
            onChange={(val) => props.setLastName(val)}
          />
          <Input
            label="Företagsnamn"
            placeholder="ex. Bookybook"
            value={props.companyName}
            onChange={(val) => props.setCompanyName(val)}
          />
          <Input
            label="Telefonnummer"
            placeholder="ex. +46 733 123 456"
            type="tel"
            value={props.phone}
            onChange={(val) => props.setPhone(val)}
          />
        </div>
      </form>
      {/* <div className="categories">
        <h2>Välj bransch</h2>
        <div className="business_cats_grid">
          <div className="cats_items">
            {categories.map((category) => (
              <div
                className={
                  "single_cat" +
                  (props.choosedCategories.some((elem) => elem === category.id)
                    ? " active"
                    : "")
                }
                onClick={() => {
                  if (
                    props.choosedCategories.some((elem) => elem === category.id)
                  ) {
                    props.filterCategoryHandler(category);
                  } else {
                    props.pushCategoryHandler(category);
                  }
                }}
              >
                {props.choosedCategories.some(
                  (elem) => elem === category.id
                ) && <FaCheckCircle className="added_icon" />}
                <div className="img_container">
                  <img src={category.image.url} alt="Category" />
                </div>
                <p>{category.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ServicesCustomization;
