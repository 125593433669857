import React from "react";
import "./SignupPhoto.scss";

// import { GiShop } from "react-icons/gi";
import { HiOutlinePhotograph } from "react-icons/hi";

const SignupPhoto = (props) => {
  return (
    <div className="SignupPhoto">
      <div className="upload">
        <input type="file" onChange={props.onChange} />
        {props.preview ? (
          <img src={props.preview} alt="preview" />
        ) : (
          <>
            <HiOutlinePhotograph color="#99ACC2" />
            <p>Ladda upp logo (helst vit)</p>
          </>
        )}
      </div>
      {/* <p>This photo will be displayed on your profile page.</p> */}
    </div>
  );
};

export default SignupPhoto;
