import React, { useEffect, useState } from "react";
import "./Review.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Logo from "../../../assets/Signup/Logo.png";
import { BsStar, BsStarFill } from "react-icons/bs";
import { getReviewInfo, rateAppointment } from "../../../store/actions/booking";

const Review = () => {
  const dispatch = useDispatch();
  const [rate, setRate] = useState(0);
  const [notes, setNotes] = useState("");

  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(getReviewInfo(token, history));
    // eslint-disable-next-line
  }, []);

  const reviewInfo = useSelector((state) => state.booking);

  const handleAppointmentRate = () => {
    if (reviewInfo) {
      dispatch(
        rateAppointment(
          token,
          rate,
          notes,
          reviewInfo.client.first_name,
          history
        )
      );
    }
  };

  return (
    <div className="Review">
      <div className="header">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="rate">
        <h1>
          Hej {reviewInfo && reviewInfo.client.first_name}, vi och många andra
          vill veta mer.{" "}
        </h1>
        <h4>Ange ditt betyg</h4>
        <div className="stars">
          <div
            className={"star" + (rate >= 1 ? " active" : "")}
            onClick={() => setRate(1)}
          >
            <BsStar />
            <BsStarFill className="fill" />
            <p>1</p>
          </div>
          <div
            className={"star" + (rate >= 2 ? " active" : "")}
            onClick={() => setRate(2)}
          >
            <BsStar />
            <BsStarFill className="fill" />
            <p>2</p>
          </div>
          <div
            className={"star" + (rate >= 3 ? " active" : "")}
            onClick={() => setRate(3)}
          >
            <BsStar />
            <BsStarFill className="fill" />
            <p>3</p>
          </div>
          <div
            className={"star" + (rate >= 4 ? " active" : "")}
            onClick={() => setRate(4)}
          >
            <BsStar />
            <BsStarFill className="fill" />
            <p>4</p>
          </div>
          <div
            className={"star" + (rate >= 5 ? " active" : "")}
            onClick={() => setRate(5)}
          >
            <BsStar />
            <BsStarFill className="fill" />
            <p>5</p>
          </div>
        </div>
        <h4>Beskriv din upplevelse </h4>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="T.ex. vilken tjänst du köpte, hur bra du blev bemött, vilka förväntningar du hade, om företaget levde upp till..."
        />
        <button disabled={rate === 0 || !notes} onClick={handleAppointmentRate}>
          Skicka
        </button>
      </div>
      <div className="bottom">© 2021 Bookybook AB | Skapad med </div>
    </div>
  );
};

export default Review;
