export const locale = {
  navbar: {
    price: "Cena",
    faq: "Često postavljanja pitanja",
    login: "Prijavite se",
    tryFree30Days: "Isprobajte 30 dana besplatno",
  },
  footer: {
    copyRight: "2021 Bookybook, Sva prava su zadršana.",
    facebookLink: "http://facebook.com/bookybook.se",
    instagramLink: "http://www.instagram.com/bookybook.se/",
    twitterLink: "#!",
  },
  landingPage: {
    easierBookings: "Lakše rezervacije.",
    satisfiedCustomers: "Zadovoljni kupci.",
    banerDescription:
      "Jednostavan, fleksibilan i moćan program koji će vašim klijentima olakšati rezervaciju termina.",
    statisticsHeading: "Pomažemo kompanijama da ostvare bolje rezultate",
    moreBookings: "više rezervacija",
    extraWorkingDays: "dodatni radni dani",
    noShows: "otkazivanje",
    workSmarter: {
      title: "RADITE PAMETNO",
      completeSolution:
        "Potpuno rešenje za upravljanje i razvoj vašeg poslovanja",
      description:
        "Naši moćni alati pomažu u povećanju prodaje, upravljaju vašim rasporedima i sarađuju sa vašim klijentima",
      easyOnlineBooking: {
        title: "Jednostavna online rezervacija",
        description:
          "Primite i povećajte broj rezervacija 24 sata dnevno 7 dana u nedelji zahvaljujući našem sistemu za rezervisanje termin prilagođenom klijentima.",
      },
      scheduling: {
        title: "Zakazivanje",
        description:
          "Profesionalni kalendarski alati koji su neverovatno laki za upotrebu.",
      },
      marketing: {
        title: "Marketing",
        description:
          "Povećajte prodaju i ispunite kalendar nizom inteligentnih alata.",
      },
      manageStaff: {
        title: "Upravljajte osobljem",
        description:
          "Dodajte svoje osoblje da biste upravljali pojedinačnim rezervacijama i dostupnošću.",
      },
      customerManagement: {
        title: "Upravljanje klijentima",
        description:
          "Unesite podatke o klijentima u bazu podataka u kojo možeti vršiti napredna pretraživanja. Sve na jednom mestu.",
      },
      analytics: {
        title: "Izveštavanje i analiza",
        description:
          "Optimizujte svoje poslovanje pomoću uvida statistike u realnom vremenu.",
      },
    },
    uniqueOnlineBooking: {
      title: "Jedinstvena online rezervacija",
      description:
        "Znate šta vašim klijentima treba. Bookibook ima sve funkcionalnosti koje su Vam potrebne. Radni dan postaje malo zabavniji kada se možete usredsrediti na posao bez potrebe da radite brže, već samo malo pametnije.",
    },
    readyToDevelopBusiness: "Spremni za razvoj vašeg poslovanja?",
    startFreePeriodToday: "Započnite svoj besplatni period već danas.",
  },
  pricing: {
    heading: "Odaberite pravi plan za Vas",
    description:
      "Povećajte prodaju, upravljajte svojim rasporedima i sarađujte sa svojim klijentima. Korišćenje Bookibooka košta manje od izgubljenih kupaca.",
    monthly: "Mesečno",
    annualy: "Godišnje",
    solo: {
      title: "Samo",
      description: "Sve što vam je potrebno za razvoj vašeg poslovanja.",
      priceMonthly: "650 RSD",
      priceAnnualy: "4000 RSD",
      type: "/mesec dana",
      checkItemsTitle: "Ovo je uračunato",
      checkItems: [
        "Online zakazivanje 24/7",
        "Sistem zakazivanja",
        "Admin konzola",
        "Alati za upravljanje",
        "Prioritetna korisnička podrška",
      ],
    },
    team: {
      title: "Tim",
      description: "Za veću kompaniju koja želi da poveća efikasnost.",
      priceMonthly: "1200 RSD",
      priceAnnualy: "8000 RSD",
      type: "/upotreba/mesec",
      checkItemsTitle: "Ovo je uračunato",
      checkItems: [
        "Beskonačno lokacija",
        "Online zakazivanje 24/7",
        "Sistem za zakazivanje",
        "Admin konzola",
        "Alati za upravljanje",
        "Prioritetna korisnička podrška",
      ],
    },
    deluxe: {
      title: "Deluks",
      description:
        "Da li imate više od 6 korisnika u kompaniji? Mi ćemo vam pomoći da povećate efikasnost. Kontaktirajte nas i javićemo Vam se sa idealnim predlogom za Vaše poslovanje!",
      checkItemsTitle: "Ovo je uračunato",
      checkItems: [
        "Beskonačno lokacija",
        "Online zakazivanje 24/7",
        "Sistem za zakazivanje",
        "Admin konzola",
        "Alati za upravljanje",
        "Prioritetna korisnička podrška",
      ],
    },
  },
  faq: {
    title: "Uobičajna pitanja",
    question1: "Da li je sistem težak za korišćenje?",
    answer1: `Ne, sistem je strukturiran tako da čak i ako niste dovoljno upoznati sa korišćenjen tehnologije, veoma lako i intuitivno možete koristiti naš sistem. Naša fantastična korisnička služba dostupna vikendom i radnim danima između 8-17.`,
    question2: "Kako započeti sa Bookybook?",
    answer2: `Zaista vam je potrebno samo nekoliko minuta i imate našu tehničku podršku, tako da se možete osećati potpuno bezbedno!`,
    question3: "Koliko traje otkazni rok?",
    answer3: `Mi nikoga ne vezujemo, vi koristite Bookybook sve dok osećate da vam je potreban. Da li ste platili 1 mesec, imate pristup Bookibook-u 1 mesec, plaćate godišnje imate sistem rezervacije godinu dana. Ne obavezujući rok, bez otkaznog roka.`,
    question4: "Koliko traje probni period?",
    answer4: `Probni period traje 30 dana u kojima možete isprobati ceo sistem, besplatno! Nakon isteka roka od 30 dana, možete odustati od korišćenja naših usluga i nemate nikavu dalju obavezu.`,
    question5: "Kada je podrška otvorena?",
    answer5: `Radno vreme naše podrške je svakog dana između 8h-18h. 
    Ukoliko kontaktirate podršku van našeg redovnog radnog vremena, odgovor će te dobiti u toku nadolazećeg radnog termina naše podrške.`,
    question6: "Kako mogu pristupiti kalendaru?",
    answer6: `Kalendaru možete pristupiti sa praktično bilo kog uređaja
    pod uslovom da ste povezani na internet.`,
    question7: "Koliko usluga mogu postaviti?",
    answer7: `Kod nas je moguće postaviti koliko god usluga želite
    nema ograničenja u broju usluga koje možete da dodate. Naravno, različite usluge mogu imati različitu cenu, dužinu i
    mogu se rezervisati u različito vreme. Možda jednostavno možeš
    ponuditi određeni tretman / uslugu nekoliko dana? Nema problema,
    sve to imate u funkcijama našeg kalendara!`,
    question8: "Kako to funkcioniše ako smo nekoliko zaposlenih u salonu?",
    answer8: `Moguće je učitavanje neograničenog broja kalendara / osoba
    pod istim preduzećem. Sistem može pružiti veoma dobre rezultate
    pregled pregleda svih rasporeda zaposlenih u isto vreme. Takođe možete postaviti nekoliko radnih mesta / salona
    više lokacija pod istim nalogom.`,
  },
  auth: {
    emailLabel: "Email adresa",
    emailPlaceholder: "Email",
    passwordLabel: "Vaša lozinka",
    passwordPlaceholder: "Lozinka",
    firstNameLabel: "Vaše ime",
    firstNamePlaceholder: "Ime",
    lastNameLabel: "Vaše prezime",
    lastNamePlaceholder: "Prezime",
    mobileNumberLabel: "Broj telefona",
    callNumber: "+381",
    createPassowrd: "Napravite lozinku",
    login: {
      title: "Prijavite vaš partnerski nalog",
      description:
        "Pristupite svom partnerskom nalogu da biste upravljali svojim poslovanjem",
      forgotPassword: "Zaboravili ste lozinku",
      loginBtn: "Prijavite se",
      dontHaveAcc: `Nemate "Partner" nalog?`,
      signupBusiness: "Registrujte svoj partnerski nalog",
      bookingAsCustomer: "Zakazite kao klijent?",
      bookingAsCustomerText: `Ovo je "Partner" sekcija, molimo idite do `,
      bookerLogin: "prijavite se kao klijent",
      instead: "umesto toga",
    },
    signup: {
      title: "Prijavite se kao partner",
      iAgree: "Slažem se sa ",
      privacyPolicy: "politika privatnosti",
      websiteTerms: "website uslovi",
      bookingTerms: "booking uslovi",
      signupBtn: "Registracija",
      alreadyHaveAcc: "Već imate nalog za zakazivanje?",
      signinNow: "Registruj se sad",
      signupAsBooker: "registrujte se kao partner",
      businessSetup: "Biznis podešavanje",
      step1: {
        title: "Koje je ime Vašeg biznisa?",
        description:
          "Ovo je ime Vašeg brenda koje će klijenti videti. Vaše ime za obračun i službeno ime možete dodati kasnije",
        businessNameLabel: "Biznis ime",
        businessNamePlaceholder: "Vaše biznis ime",
      },
      step2: {
        title: "Odaberite glavnu kategoriju Vašeg biznisa",
      },
      step3: {
        title: "Odaberite još kategorija Vašeg biznisa",
        description:
          "Ako Vaš biznes može biti opisan sa više kategorija, izaberite ih i primenite",
      },
      step4: {
        title: "Broj ljudi u Vašem timu?",
        description:
          "Ovo će nam pomoći da podesimo kalendar adekvatno broju ljudi u Vašem timu. Članove tima možete dodati naknadno.",
        justMe: "Samo sam ja",
      },
    },
  },
  previous: "Prethodno",
  nextStep: "Sledeći korak",
  save: "Upamti",
  and: "i",
  tryFree: "Probaj besplatno",
  reviewExample: {
    description:
      "80% naših kupaca rezerviše onlajn preko naše veb stranice. Celo naše poslovanje vodimo koristeći bookybook, a oni su tako laki za korišćenje!: 'Milica Stevanovic'",
    reviewerName: "Sofijsa R",
    reviewerSalon: "Salon lepote Milica",
  },
};
