export const SET_USER = "SET_USER";
export const SET_PLACE = "SET_PLACE";

export const SET_TYPE_OF_SERVICES = "SET_TYPE_OF_SERVICES";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";

export const FETCH_OPENING_HOURS = "FETCH_OPENING_HOURS";

export const SET_SERVICES = "SET_SERVICES";

export const SET_CALENDAR = "SET_CALENDAR";

export const SET_WORKING_HOURS = "SET_WORKING_HOURS";

export const SET_WIDGET_INFO = "SET_WIDGET_INFO";

export const SET_BOOKING_INFO = "SET_BOOKING_INFO";

export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";
