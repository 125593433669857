import React, { useState } from "react";
import "./Verification.scss";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//components
import SignupHeader from "../../../components/Signup/SignupHeader/SignupHeader";
//containers
import WidgetCustomization from "../../Signup/WidgetCustomization/WidgetCustomization";
import ServicesCustomization from "../../Signup/ServicesCustomization/ServicesCustomization";
import IframeEmbed from "../../Signup/IframeEmbed/IframeEmbed";
import { widgetCustomization, finishSignUp } from "../../../store/actions/user";

const Verification = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [previewImage, setPreviewImage] = useState(null);
  const [logo, setLogo] = useState("");
  const [choosenColor, setChoosenColor] = useState({
    first: "#1A73E8",
    second: "#1A56A6",
  });
  const { token } = useParams();

  const setPlaceLogoHandler = (e) => {
    let img = e.target.files[0];
    setPreviewImage(URL.createObjectURL(img));
    setLogo(img);
  };
  const [choosedCategories, setChoosedCategories] = useState([]);
  const filterCategoryHandler = (suggestion) => {
    const updatedCategories = choosedCategories.filter(
      (id) => id !== suggestion.id
    );
    setChoosedCategories(updatedCategories);
  };
  const pushCategoryHandler = (suggestion) => {
    const updatedCategories = [...choosedCategories];
    updatedCategories.push(suggestion.id);
    setChoosedCategories(updatedCategories);
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [previewOpened, setPreviewOpened] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const submitStep1 = () => {
    const fd = new FormData();
    if (logo) {
      fd.append("logo", logo);
    }
    fd.append(
      "background_color",
      `linear-gradient(117.58deg, ${choosenColor.first} 0%, ${choosenColor.second} 100%)`
    );
    fd.append("first_name", firstName);
    fd.append("last_name", lastName);
    fd.append("company_name", companyName);
    fd.append("phone", phone);
    fd.append("uuid", token);
    dispatch(widgetCustomization(fd, () => setStep(3)));
  };

  const finishSignUpHandler = () => {
    dispatch(finishSignUp(token));
  };

  return (
    <div className={"Verification" + (previewOpened ? " mobileActive" : "")}>
      <SignupHeader step={step} />

      {step === 1 && (
        <WidgetCustomization
          choosenColor={choosenColor}
          setChoosenColor={setChoosenColor}
          previewImage={previewImage}
          setPlaceLogoHandler={setPlaceLogoHandler}
          previewOpened={previewOpened}
          setPreviewOpened={setPreviewOpened}
        />
      )}
      {step === 2 && (
        <ServicesCustomization
          choosedCategories={choosedCategories}
          setChoosedCategories={setChoosedCategories}
          filterCategoryHandler={filterCategoryHandler}
          pushCategoryHandler={pushCategoryHandler}
          firstName={firstName}
          lastName={lastName}
          companyName={companyName}
          phone={phone}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setCompanyName={setCompanyName}
          setPhone={setPhone}
        />
      )}

      {step === 3 && (
        <IframeEmbed
          showCode={showCode}
          setShowCode={setShowCode}
          token={token}
        />
      )}
      <div className={"signupSubmit" + (step !== 1 ? " backable" : "")}>
        {step !== 1 && (
          <button
            onClick={() => {
              step !== 3
                ? setStep((p) => p - 1)
                : showCode
                ? setShowCode(false)
                : setStep((p) => p - 1);
            }}
            className="back"
          >
            Gå tillbaka
          </button>
        )}
        <button
          onClick={() => {
            step < 3
              ? step === 2
                ? submitStep1()
                : setStep((p) => p + 1)
              : finishSignUpHandler();
          }}
          className="next"
        >
          Fortsätt
        </button>
      </div>
    </div>
  );
};

export default Verification;
