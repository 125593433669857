import * as actionTypes from "../actions/actionTypes";
import axios from "../../utility/axios/axios";

import { startLoading, finishLoading } from "./loader";
import { successNotification } from "./notification";

export const setPlace = (place) => {
  return {
    type: actionTypes.SET_PLACE,
    place: place,
  };
};

export const postPlace = (callback) => {
  return (dispatch) => {
    dispatch(startLoading());

    axios
      .post("/api/b2b/signup/step/2", FormData, {})
      .then((response) => {
        // console.log('PLACE', response.data.data.user)
        // dispatch(setPlace(response.data.data.user))
        dispatch(setPlace(response.data.data.place));
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        callback(3);
      })
      .catch((error) => {
        // if (error.response.status === 422) {
        //   Object.keys(error.response.data.errors).map((errorData) =>
        //     error.response.data.errors[errorData].map((message) =>
        //       dispatch(errorsNotification(message))
        //     )
        //   );
        // } else dispatch(errorsNotification(error.response.data.message));

        dispatch(finishLoading());
      });
  };
};
