import React, { useState, useEffect } from "react";
import "../Login/Login.scss";
import "./Signup2.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FaCheckCircle } from "react-icons/fa";
import { GrClose } from "react-icons/gr";

import Bookybook from "../../../assets/Signup/Logo.png";
import SignupForm from "../../../components/Signup/SignupForm/SignupForm";
import Input from "../../../components/Signup/SignupInput/SignupInput";
import BookingProgress from "../../../components/Signup/BookingProgress/BookingProgress";
import { BiArrowBack } from "react-icons/bi";

import { fetchCategories } from "../../../store/actions/categories";

import justMe from "../../../assets/Signup/justMe.svg";
import twoToFive from "../../../assets/Signup/2to5.svg";
import sixToTen from "../../../assets/Signup/6to10.svg";
import eleven from "../../../assets/Signup/11+.svg";
import { step2, step3, step4 } from "../../../store/actions/user";
import { locale } from "../../../translate/serbian";

const Signup2 = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [mainCategory, setMainCategory] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const placeFromStore = useSelector((state) => state.place);

  const [place, setPlace] = useState({
    categorieNames: [],
    categories: [],
    teamSize: 1,
  });

  useEffect(() => {
    if (props.categories.length === 0) {
      props.fetchCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushCategoryHandler = (suggestion) => {
    setPlace((u) => {
      const updatedCategorieNames = [...u.categorieNames];
      updatedCategorieNames.push(suggestion.name);
      const updatedCategories = [...u.categories];
      updatedCategories.push(suggestion.id);
      return {
        ...u,
        categorieNames: updatedCategorieNames,
        categories: updatedCategories,
      };
    });
  };
  const filterCategoryHandler = (suggestion) => {
    setPlace((u) => {
      const updatedCategorieNames = u.categorieNames.filter(
        (name) => name !== suggestion.name
      );
      const updatedCategories = u.categories.filter(
        (id) => id !== suggestion.id
      );
      return {
        ...u,
        categorieNames: updatedCategorieNames,
        categories: updatedCategories,
      };
    });
  };
  const mainCategoryHandler = (suggestion) => {
    setPlace((u) => {
      // const updatedCategorieNames = [...u.categorieNames];
      // updatedCategorieNames.push(suggestion.name);
      const filteredCategories = place.categories.filter(
        (elem) => elem !== suggestion.id
      );
      const filteredCategoriesNames = place.categorieNames.filter(
        (elem) => elem !== suggestion.name
      );
      return {
        ...u,
        categorieNames: filteredCategoriesNames,
        categories: filteredCategories,
      };
    });
    setMainCategory(suggestion.id);
  };

  const handleTeamSize = (val) => {
    setPlace((p) => ({ ...p, teamSize: val }));
  };

  const handleNext = () => {
    // nextStep();
    if (step === 2) {
      dispatch(step2(businessName, () => setStep(3)));
    } else if (step === 3) {
      dispatch(step3(placeFromStore.id, mainCategory, () => setStep(4)));
    } else if (step === 4) {
      dispatch(step4(placeFromStore.id, place.categories, () => setStep(5)));
    } else if (step === 5) {
      window.location.href = process.env.REACT_APP_CALENDAR_LINK;
    }
  };

  return (
    <>
      {step === 1 && (
        <div className="Login signup">
          <div className="topArea">
            <div className="back" onClick={() => history.goBack()}>
              <BiArrowBack />
            </div>
            <Link to="/">
              <img className="logo" src={Bookybook} alt="Bookybook" />
            </Link>
          </div>
          <div className="loginContent">
            <h1>{locale.auth.signup.title}</h1>
            <SignupForm onSubmit={() => setStep(2)} />
          </div>
          <div className="loginContent professionals">
            <h1>{locale.auth.login.bookingAsCustomer}</h1>
            <p>
              {locale.auth.login.bookingAsCustomerText}{" "}
              <a href={process.env.REACT_APP_SIGNUP_LINK}>
                {locale.auth.signup.signupAsBooker}{" "}
              </a>{" "}
              {locale.auth.login.instead}
            </p>
          </div>
          <span className="allRights">© {locale.footer.copyRight}</span>
        </div>
      )}
      {step !== 1 && (
        <>
          <div className="category_top_area">
            <div className="step2_previous">
              <div
                onClick={() => {
                  history.replace("/login");
                }}
              >
                <GrClose />
              </div>
              {step !== 2 && (
                <div
                  className="previous_cat"
                  onClick={() => setStep((p) => (p = p - 1))}
                >
                  {locale.previous}
                </div>
              )}
            </div>
            <button onClick={handleNext}>
              {step === 5 ? locale.save : locale.nextStep}
            </button>
          </div>
          <BookingProgress completed={(step - 2) * 25} />

          <CSSTransition
            in={step === 2}
            timeout={500}
            classNames="my-node"
            mountOnEnter
            unmountOnExit
          >
            <div className="choose_category main">
              <div className="content">
                <div className="category_heading">
                  <h4>{locale.auth.signup.businessSetup}</h4>
                  <h1>{locale.auth.signup.step1.title}</h1>
                  <h5>{locale.auth.signup.step1.description}</h5>
                </div>
                <div className="addNameContent">
                  <Input
                    label={locale.auth.signup.step1.businessNameLabel}
                    placeholder={
                      locale.auth.signup.step1.businessNamePlaceholder
                    }
                    value={businessName}
                    onChange={(val) => setBusinessName(val)}
                  />
                </div>
              </div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={step === 3}
            timeout={500}
            classNames="my-node"
            mountOnEnter
            unmountOnExit
          >
            <div className="choose_category main step2">
              <div className="content">
                <div className="category_heading">
                  <h4>{locale.auth.signup.businessSetup}</h4>
                  <h1>{locale.auth.signup.step2.title}</h1>
                </div>
                <div className="business_cats_grid">
                  <div className="cats_items">
                    {props.categories.map((category, index) => (
                      <div
                        key={index}
                        className={
                          "single_cat" +
                          (mainCategory === category.id ? " active" : "")
                        }
                        onClick={() => {
                          mainCategoryHandler(category);
                        }}
                      >
                        {mainCategory === category.id && (
                          <FaCheckCircle className="added_icon" />
                        )}
                        {/* <GiHairStrands className="main_icon" /> */}
                        <div className="img_container">
                          <img src={category.image.url} alt="Category" />
                        </div>
                        <p>{category.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={step === 4}
            timeout={500}
            classNames="my-node"
            mountOnEnter
            unmountOnExit
          >
            <div className="choose_category step2">
              <div className="content">
                <div className="category_heading">
                  <h4>{locale.auth.signup.businessSetup}</h4>
                  <h1>{locale.auth.signup.step3.title}</h1>
                  <h5>{locale.auth.signup.step3.description}</h5>
                </div>
                <div className="business_cats_grid">
                  <div className="cats_items">
                    {props.categories.map((category, index) => (
                      <div
                        key={index}
                        className={
                          "single_cat" +
                          (place.categories.some((elem) => elem === category.id)
                            ? " active"
                            : "") +
                          (mainCategory === category.id ? " disabled" : "")
                        }
                        onClick={() => {
                          if (mainCategory !== category.id) {
                            if (
                              place.categories.some(
                                (elem) => elem === category.id
                              )
                            ) {
                              filterCategoryHandler(category);
                            } else {
                              pushCategoryHandler(category);
                            }
                          }
                        }}
                      >
                        {place.categories.some(
                          (elem) => elem === category.id
                        ) && <FaCheckCircle className="added_icon" />}
                        <div className="img_container">
                          <img src={category.image.url} alt="Category" />
                        </div>
                        <p>{category.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={step === 5}
            timeout={500}
            classNames="my-node"
            mountOnEnter
            unmountOnExit
          >
            <div className="choose_category step2">
              <div className="content">
                <div className="category_heading">
                  <h4>{locale.auth.signup.businessSetup}</h4>
                  <h1>{locale.auth.signup.step4.title}</h1>
                  <h5>{locale.auth.signup.step4.description}</h5>
                </div>
                <div className="business_cats_grid team">
                  <div className="cats_items team">
                    <div
                      className={
                        "single_cat" + (place.teamSize === 1 ? " active" : "")
                      }
                      onClick={() => handleTeamSize(1)}
                    >
                      {place.teamSize === 1 && (
                        <FaCheckCircle className="added_icon" />
                      )}
                      <div className="img_container">
                        <img src={justMe} alt="Category" />
                      </div>
                      <p>{locale.auth.signup.step4.justMe}</p>
                    </div>
                    <div
                      className={
                        "single_cat" + (place.teamSize === 2 ? " active" : "")
                      }
                      onClick={() => handleTeamSize(2)}
                    >
                      {place.teamSize === 2 && (
                        <FaCheckCircle className="added_icon" />
                      )}
                      <div className="img_container">
                        <img src={twoToFive} alt="Category" />
                      </div>
                      <p>2-5</p>
                    </div>
                    <div
                      className={
                        "single_cat" + (place.teamSize === 6 ? " active" : "")
                      }
                      onClick={() => handleTeamSize(6)}
                    >
                      {place.teamSize === 6 && (
                        <FaCheckCircle className="added_icon" />
                      )}
                      <div className="img_container">
                        <img src={sixToTen} alt="Category" />
                      </div>
                      <p>6-10</p>
                    </div>
                    <div
                      className={
                        "single_cat" + (place.teamSize === 11 ? " active" : "")
                      }
                      onClick={() => handleTeamSize(11)}
                    >
                      {place.teamSize === 11 && (
                        <FaCheckCircle className="added_icon" />
                      )}
                      <div className="img_container">
                        <img src={eleven} alt="Category" />
                      </div>
                      <p>11+</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
          <div className="mobileBottom">
            <button onClick={handleNext}>
              {step === 5 ? locale.save : locale.nextStep}
            </button>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
  };
};

export default connect(mapStateToProps, {
  // postUser,
  // postPlace,
  fetchCategories,
})(Signup2);
