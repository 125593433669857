import React from "react";
import { Link } from "react-router-dom";
import { locale } from "../../translate/serbian";

const PageLayoutBottom = () => {
  return (
    <div class="bg-white">
      <div class="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span class="block">{locale.landingPage.readyToDevelopBusiness}</span>
          <span class="block">{locale.landingPage.startFreePeriodToday}</span>
        </h2>
        <div class="mt-8 flex justify-center">
          <div class="inline-flex rounded-md shadow">
            <Link
              to="/signup"
              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              {locale.navbar.tryFree30Days}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayoutBottom;
