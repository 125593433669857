import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/Signup/Logo.png";
import "alpinejs";
import { locale } from "../../../translate/serbian";

const mobileOpenButton = `<button
x-on:click="mobileMenuOpen = true"
type="button"
class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
aria-expanded="false"
>
<span class="sr-only">Open menu</span>
<svg
  class="h-6 w-6"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
  aria-hidden="true"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M4 6h16M4 12h16M4 18h16"
  />
</svg>
</button>`;

const mobileCloseBtn = `<button
x-on:click="mobileMenuOpen = false"
type="button"
class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
>
<span class="sr-only">Close menu</span>
<svg
  class="h-6 w-6"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
  aria-hidden="true"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M6 18L18 6M6 6l12 12"
  />
</svg>
</button>`;

const MobileOpenBtn = () => (
  <div dangerouslySetInnerHTML={{ __html: mobileOpenButton }} />
);

const MobileCloseBtn = () => (
  <div dangerouslySetInnerHTML={{ __html: mobileCloseBtn }} />
);

const NavigationTW = () => {
  return (
    <>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span class="sr-only">Workflow</span>
              <img class="h-6 w-auto sm:h-8" src={Logo} alt="" />
            </Link>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <MobileOpenBtn />
          </div>
          <nav class="hidden md:flex space-x-10">
            <Link
              to="/price"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              {locale.navbar.price}
            </Link>

            <Link
              to="/faq"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              {locale.navbar.faq}
            </Link>

            {/* <Link
              to="/contact"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Kontakta oss
            </Link> */}
          </nav>
          <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link
              to="/login"
              class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
            >
              {locale.navbar.login}
            </Link>
            <Link
              to="/signup"
              class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              {locale.navbar.tryFree30Days}
            </Link>
          </div>
        </div>
      </div>
      <div
        x-show="mobileMenuOpen"
        class="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
      >
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <Link to="/">
                <img class="h-6 w-auto sm:h-8" src={Logo} alt="Workflow" />
              </Link>
              <div class="-mr-2">
                <MobileCloseBtn />
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <Link
                  to="/price"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <span class="ml-3 text-base font-medium text-gray-900">
                    {locale.navbar.price}
                  </span>
                </Link>

                <Link
                  to="/faq"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <span class="ml-3 text-base font-medium text-gray-900">
                    {locale.navbar.faq}
                  </span>
                </Link>
                <Link
                  to="/login"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <span class="ml-3 text-base font-medium text-gray-900">
                    {locale.navbar.login}
                  </span>
                </Link>
                <Link
                  to="/signup"
                  class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
                >
                  {locale.navbar.tryFree30Days}
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationTW;
