import React, { useRef, useState, useEffect } from "react";
import "./IframeEmbed.scss";
import { useSelector, useDispatch } from "react-redux";
import { BiCode } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { FaWordpress } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Input from "../../../components/Signup/SignupInput2/SignupInput2";
import { sendInstructions } from "../../../store/actions/user";

const IframeEmbed = ({ showCode, setShowCode, token }) => {
  const dispatch = useDispatch();
  const [showJavascriptDesc, setShowJavascriptDesc] = useState(false);
  const [showWordpressDesc, setShowWordpressDesc] = useState(false);
  const jsIframeRef = useRef(null);
  const [wpCopied, setWpCopied] = useState(false);
  const [jsCopied, setJsCopied] = useState(false);
  const [instructionsEmail, setInstructionsEmail] = useState("");
  const { widgetInfo } = useSelector((state) => state.user);

  useEffect(() => {
    if (jsCopied) {
      const func = () => setJsCopied(false);
      setTimeout(func, 2000);
    }
  }, [jsCopied]);
  useEffect(() => {
    if (wpCopied) {
      const func = () => setWpCopied(false);
      setTimeout(func, 2000);
    }
  }, [wpCopied]);
  const handleTextCopyJs = (e) => {
    if (jsIframeRef.current) {
      jsIframeRef.current.select();
      document.execCommand("copy");
      e.target.focus();
    }
  };
  const sendInstructionsEmail = () => {
    dispatch(sendInstructions(instructionsEmail, token));
  };

  return (
    <div className="step3">
      <h1>Installera koden på din hemsida</h1>
      <h5>
        Välj hur du skulle vilja lägga till Bookybook koden på din hemsida
      </h5>
      {!showCode ? (
        <div className="row">
          <div className="col">
            <div className="first">
              <BiCode />
              <h2>I’ll do it myself.</h2>
              <p>You must have access to your page template source.</p>
            </div>
            <button onClick={() => setShowCode(true)}>Show me the code</button>
          </div>
          <div className="col">
            <div className="first">
              <FiMail />
              <h2>Send instructions to my developer</h2>
              <Input
                placeholder="ex. utvecklare@foretag.se"
                label="Utvecklarens email"
                type="email"
                value={instructionsEmail}
                onChange={(val) => setInstructionsEmail(val)}
              />
            </div>
            <button onClick={sendInstructionsEmail}>Send</button>
          </div>
        </div>
      ) : (
        <div className="chooseType">
          <div className={"codeType" + (showJavascriptDesc ? " active" : "")}>
            <div
              className={"displayCode" + (showJavascriptDesc ? " active" : "")}
              onClick={() => setShowJavascriptDesc((p) => !p)}
            >
              <BiCode />
              <h3>JavaScript</h3>
            </div>
            <div className="desc">
              <p className="descText">{`Klistra in denna koden precis innan </body> taggen stängs`}</p>
              <textarea
                className="iframe"
                ref={jsIframeRef}
                readOnly
                value={widgetInfo ? widgetInfo.code : ""}
              />
              <div className="descRow">
                {document.queryCommandSupported("copy") && (
                  <button
                    onClick={(e) => {
                      handleTextCopyJs(e);
                      setJsCopied(true);
                    }}
                  >
                    Kopiera
                  </button>
                )}
                {jsCopied && <div className="copied">Copied!</div>}
                <div className="descInfo">
                  <AiOutlineInfoCircle />
                  Behöver du hjälp?&nbsp;
                  <a href="mailto:hej@bookybook.at">Kontakta oss.</a>
                </div>
              </div>
            </div>
          </div>
          <div className={"codeType" + (showWordpressDesc ? " active" : "")}>
            <div
              className={"displayCode" + (showWordpressDesc ? " active" : "")}
              onClick={() => setShowWordpressDesc((p) => !p)}
            >
              <FaWordpress />
              <h3>WordPress</h3>
            </div>
            <div className="desc">
              <p className="descText">
                1. Logga in på din WordPress admin panel
              </p>
              <p className="descText">{`2. Gå till Appearance > Theme Editor `}</p>
              <p className="descText">
                3. Under Theme Files (till höger), klicka på “Theme Footer”
                (footer.php)
              </p>
              <p className="descText">{`4. Klistra in din din Bookybook kod innan </body> taggen längre ner`}</p>
              <p className="descText">
                5. Klicka på “Update file” för att spara ändringar
              </p>
              <textarea
                className="iframe"
                ref={jsIframeRef}
                readOnly
                value={widgetInfo ? widgetInfo.code : ""}
              />
              <div className="descRow">
                {document.queryCommandSupported("copy") && (
                  <button
                    onClick={(e) => {
                      handleTextCopyJs(e);
                      setWpCopied(true);
                    }}
                  >
                    Kopiera
                  </button>
                )}
                {wpCopied && <div className="copied">Copied!</div>}
                <div className="descInfo">
                  <AiOutlineInfoCircle />
                  Behöver du hjälp?&nbsp;
                  <a href="mailto:hej@bookybook.at">Kontakta oss.</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IframeEmbed;
