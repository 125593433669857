import React from "react";
import "./WidgetCustomization.scss";
import SignupPhoto from "../../../components/Signup/SignupPhoto/SignupPhoto";
import {
  BsCheck,
  BsChevronRight,
  BsChevronDown,
  BsChevronLeft,
} from "react-icons/bs";
import { AiOutlineUser, AiOutlineClockCircle } from "react-icons/ai";
import LogoGrey from "../../../assets/Signup/LogoGrey.png";

const WidgetCustomization = ({
  previewImage,
  setPlaceLogoHandler,
  setChoosenColor,
  choosenColor,
  previewOpened,
  setPreviewOpened,
}) => {
  return (
    <div className={"stepContainer" + (previewOpened ? " active" : "")}>
      <div
        className="mobilePreview"
        onClick={() => setPreviewOpened((p) => !p)}
      >
        {previewOpened ? (
          <>
            <BsChevronLeft />
            <p>Back to setup</p>
          </>
        ) : (
          <>
            <p>Preview</p>
            <BsChevronRight />
          </>
        )}
      </div>
      <div className="step1">
        <div className="left">
          <h1>Anpassa utseendet</h1>
          <h5>Ladda upp din logotyp</h5>
          <span>frivilligt men rekommenderas starkt</span>
          <SignupPhoto preview={previewImage} onChange={setPlaceLogoHandler} />
          <h5>Välj färg</h5>
          <div className="colors">
            <div
              className="singleColor"
              style={{
                backgroundImage:
                  "linear-gradient(117.58deg, #1A73E8 0%, #1A56A6 100%)",
              }}
              onClick={() =>
                setChoosenColor({ first: "#1A73E8", second: "#1A56A6" })
              }
            >
              {choosenColor.first === "#1A73E8" && <BsCheck />}
            </div>
            <div
              className="singleColor"
              style={{
                backgroundImage:
                  "linear-gradient(117.58deg, #00A3AD 0%, #077278 100%)",
              }}
              onClick={() =>
                setChoosenColor({ first: "#00A3AD", second: "#077278" })
              }
            >
              {choosenColor.first === "#00A3AD" && <BsCheck />}
            </div>
            <div
              className="singleColor"
              style={{
                backgroundImage:
                  "linear-gradient(117.58deg, #C61575 0%, #980D58 100%)",
              }}
              onClick={() =>
                setChoosenColor({ first: "#C61575", second: "#980D58" })
              }
            >
              {choosenColor.first === "#C61575" && <BsCheck />}
            </div>
            <div
              className="singleColor"
              style={{
                backgroundImage:
                  "linear-gradient(116.92deg, #46627E 0.93%, #253342 100%)",
              }}
              onClick={() =>
                setChoosenColor({ first: "#46627E", second: "#253342" })
              }
            >
              {choosenColor.first === "#46627E" && <BsCheck />}
            </div>
            <div
              className="singleColor"
              style={{
                backgroundImage:
                  "linear-gradient(116.92deg, #825BF0 0.93%, #401DBA 100%)",
              }}
              onClick={() =>
                setChoosenColor({ first: "#825BF0", second: "#401DBA" })
              }
            >
              {choosenColor.first === "#825BF0" && <BsCheck />}
            </div>
          </div>
        </div>
        <div className="right">
          <div
            className="preview"
            style={{
              backgroundImage: `linear-gradient(119.15deg, ${choosenColor.first} 0%, ${choosenColor.second} 100%)`,
            }}
          >
            {previewImage ? (
              <img src={previewImage} alt="Company Logo" />
            ) : (
              <div style={{ width: 170, height: 30 }} />
            )}
            <h1>
              Hej!{" "}
              <span role="img" aria-label="icon">
                👋🏻
              </span>
            </h1>
            <h3>Vad vill du ha hjälp med?</h3>
          </div>
          <div className="personellPreview">
            <h2>Starta en bokning</h2>
            <div className="personell">
              <div className="singlePersonell abs">
                <AiOutlineUser />
              </div>
              <div className="singlePersonell">
                <AiOutlineUser />
              </div>
              <div>
                <p>Nästa lediga tid från</p>
                <div className="hours">
                  <AiOutlineClockCircle style={{ color: choosenColor.first }} />
                  <span>10:30, onsdag 24 feb.</span>
                </div>
              </div>
            </div>
            <button style={{ backgroundColor: choosenColor.first }}>
              Boka en tid med oss <BsChevronRight />
            </button>
          </div>
          <div className="expand">
            <h1>Detta säger våra kunder om oss</h1>
            <BsChevronDown />
          </div>
          <div className="bottomWidgetArea">
            Powered by <img src={LogoGrey} alt="Logo grey" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetCustomization;
