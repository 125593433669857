import React from "react";
import "alpinejs";
import { locale } from "../../translate/serbian";

const questionTemplate = `<div class="bg-gray-50">
<div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
  <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
    <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
      ${locale.faq.title}
    </h2>
    <dl class="mt-6 space-y-6 divide-y divide-gray-200">
      <div class="pt-6" x-data="{open: true}">
        <dt class="text-lg">
          <button
            x-on:click="open = !open"
            class="text-left w-full flex justify-between items-start text-gray-400"
          >
            <span class="font-medium text-gray-900">
              ${locale.faq.question1}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                x-show="open"
                class="-rotate-180 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <svg
                x-show="!open"
                class="-rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd
          class="mt-2 pr-12"
          x-show.transition="open"
        >
          <p class="text-base text-gray-500">
					${locale.faq.answer1}
          </p>
        </dd>
      </div>

      <div class="pt-6" x-data="{open: true}">
        <dt class="text-lg">
          <button
            x-on:click="open = !open"
            class="text-left w-full flex justify-between items-start text-gray-400"
          >
            <span class="font-medium text-gray-900">
						${locale.faq.question2}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                x-show="open"
                class="-rotate-180 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <svg
                x-show="!open"
                class="-rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd
          x-show.transition="open"
          class="mt-2 pr-12"
        >
          <p class="text-base text-gray-500">
					${locale.faq.answer2}
          </p>
        </dd>
      </div>

      <div class="pt-6" x-data="{open: true}">
        <dt class="text-lg">
          <button
            x-on:click="open = !open"
            class="text-left w-full flex justify-between items-start text-gray-400"
          >
            <span class="font-medium text-gray-900">
						${locale.faq.question3}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                x-show="open"
                class="-rotate-180 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <svg
                x-show="!open"
                class="-rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd
          x-show.transition="open"
          class="mt-2 pr-12"
        >
          <p class="text-base text-gray-500">
					${locale.faq.answer3}
          </p>
        </dd>
      </div>

      <div class="pt-6" x-data="{open: true}">
        <dt class="text-lg">
          <button
            x-on:click="open = !open"
            class="text-left w-full flex justify-between items-start text-gray-400"
          >
            <span class="font-medium text-gray-900">
						${locale.faq.question4}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                x-show="open"
                class="-rotate-180 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <svg
                x-show="!open"
                class="-rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd
          class="mt-2 pr-12"
          x-show.transition="open"
        >
          <p class="text-base text-gray-500">
					${locale.faq.answer4}
          </p>
        </dd>
      </div>

      <div class="pt-6" x-data="{open: true}">
        <dt class="text-lg">
          <button
            x-on:click="open = !open"
            class="text-left w-full flex justify-between items-start text-gray-400"
          >
            <span class="font-medium text-gray-900">
						${locale.faq.question5}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                x-show="open"
                class="-rotate-180 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <svg
                x-show="!open"
                class="-rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd
          x-show.transition="open"
          class="mt-2 pr-12"
        >
          <p class="text-base text-gray-500">
					${locale.faq.answer5}
          </p>
        </dd>
      </div>

      <div class="pt-6" x-data="{open: true}">
        <dt class="text-lg">
          <button
            x-on:click="open = !open"
            class="text-left w-full flex justify-between items-start text-gray-400"
          >
            <span class="font-medium text-gray-900">
						${locale.faq.question6}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                x-show="open"
                class="-rotate-180 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <svg
                x-show="!open"
                class="-rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd
          class="mt-2 pr-12"
          x-show.transition="open"
        >
          <p class="text-base text-gray-500">
					${locale.faq.answer6}
          </p>
        </dd>
      </div>

      <div class="pt-6" x-data="{open: true}">
        <dt class="text-lg">
          <button
            x-on:click="open = !open"
            class="text-left w-full flex justify-between items-start text-gray-400"
          >
            <span class="font-medium text-gray-900">
						${locale.faq.question7}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                x-show="open"
                class="-rotate-180 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <svg
                x-show="!open"
                class="-rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd
          class="mt-2 pr-12"
          x-show.transition="open"
        >
          <p class="text-base text-gray-500">
					${locale.faq.answer7}
          </p>
        </dd>
      </div>

      <div class="pt-6" x-data="{open: true}">
        <dt class="text-lg">
          <button
            x-on:click="open = !open"
            class="text-left w-full flex justify-between items-start text-gray-400"
          >
            <span class="font-medium text-gray-900">
						${locale.faq.question8}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                x-show="open"
                class="-rotate-180 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <svg
                x-show="!open"
                class="-rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd
          class="mt-2 pr-12"
          x-show.transition="open"
        >
          <p class="text-base text-gray-500">
					${locale.faq.answer8}
          </p>
        </dd>
      </div>
    </dl>
  </div>
</div>
</div>`;

const Questions = () => (
  <div dangerouslySetInnerHTML={{ __html: questionTemplate }} />
);

export default Questions;
