import React from "react";
import "./SignupInput2.scss";

const SignupInput2 = (props) => {
  return (
    <div className="SignupInput2">
      <span>{props.label}</span>
      <input
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
      />
    </div>
  );
};

export default SignupInput2;
