import * as actionTypes from "../actions/actionTypes";
import axios from "../../utility/axios/axios";

import { startLoading, finishLoading } from "./loader";
import { successNotification, errorsNotification } from "./notification";

export const setBookingInfo = (payload) => {
  return {
    type: actionTypes.SET_BOOKING_INFO,
    payload,
  };
};

export const getBookingInfo = (id, history) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/api/b2b/booking/${id}`)
      .then((response) => {
        dispatch(setBookingInfo(response.data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        // dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
        history.push("/");
      });
  };
};

export const cancelAppointment = (cancellation_code, history) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/cancel-appointment", { cancellation_code })
      .then((response) => {
        dispatch(finishLoading());
        dispatch(successNotification(response.data.message));
        history.push("/");
      })
      .catch((error) => {
        console.log("ERROR CANCELATION BOOKING", error.response);
        dispatch(
          errorsNotification(error.response && error.response.data.message)
        );
        dispatch(finishLoading());
      });
  };
};

export const getReviewInfo = (id, history) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/api/appointment/from-token/${id}`)
      .then((response) => {
        dispatch(setBookingInfo(response.data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        // dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
        history.push("/");
      });
  };
};

export const rateAppointment = (token, rating, text, author_name, history) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/rate-appointment", { rating, text, author_name, token })
      .then((response) => {
        dispatch(finishLoading());
        dispatch(successNotification(response.data.message));
        history.push("/");
      })
      .catch((error) => {
        // dispatch(errorsNotification(error.response.data.message));
        dispatch(
          errorsNotification(error.response && error.response.data.message)
        );
        dispatch(finishLoading());
      });
  };
};
