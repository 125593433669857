import React from "react";
import { Link } from "react-router-dom";
import "./SignupHeader.scss";
import Logo from "../../../assets/Signup/Logo.png";

const SignupHeader = ({ step }) => {
  return (
    <div className="SignupHeader">
      <Link to="/">
        <img src={Logo} alt="Logo" />
      </Link>
      <div className="steps">
        <p>Steg {step} / 3</p>
        <div className="dots">
          <div className={"dot" + (step >= 1 ? " active" : "")} />
          <div className={"dot" + (step >= 2 ? " active" : "")} />
          <div className={"dot" + (step >= 3 ? " active" : "")} />
        </div>
      </div>
    </div>
  );
};

export default SignupHeader;
