import React from "react";
import "./App.scss";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

import LoadingScreen from "./containers/views/LoadingScreen/LoadingScreen";

import Login from "./containers/views/Login/Login";
import Signup2 from "./containers/views/Signup2/Signup2";
import Verification from "./containers/views/Verification/Verification";
import Review from "./containers/views/Review/Review";

//Tailwind
import LandingPageTW from "./containers/views/LandingPageTW/LandingPageTW";
import FAQTW from "./containers/views/FAQTW/FAQTW";
import PricingTW from "./containers/views/PricingTW/PricingTW";
import BookingInfo from "./containers/views/BookingInfo/BookingInfo";

import Notification from "./containers/Notification/Notification";

const useStyles = makeStyles((theme) => ({
  variantSuccess: { backgroundColor: "#00A3AD !important" },
  variantError: { backgroundColor: "#F64E60 !important" },
}));

function App() {
  const classes = useStyles();

  return (
    <SnackbarProvider
      classes={classes}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      maxSnack={2}
    >
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <LoadingScreen />
            <Notification />
            <Switch>
              <Route path="/" exact component={LandingPageTW} />
              <Route path="/price" component={PricingTW} />
              <Route path="/faq" component={FAQTW} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup2} />
              <Route path="/verification/:token" component={Verification} />
              <Route path="/review/:token" component={Review} />
              <Route path="/booking/:id" component={BookingInfo} />

              <Redirect to="/" />
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    </SnackbarProvider>
  );
}

export default App;
